<template>
	<div class="row">
		<div class="col-3">
			<h3>Draggable 1</h3>
			<draggable
				class="drag-group1"
				:list="list1"
				group="people"
				@change="log"
			>
				<transition-group
					type="transition"
					:name="!drag ? 'flip-list' : null"
				
					class="drag-group1-item" v-for="(element, index) in list1"
					:key="element.name" >
					{{ element.name }} {{ index }}
				</transition-group>
			</draggable>
		</div>

		<div class="col-3">
			<h3>Draggable 2</h3>
			<draggable
				class="drag-group2"
				:list="list2"
				group="people"
				@change="log"
			>
				<div
					class="drag-group2-item"
					v-for="(element, index) in list2"
					:key="element.name"
				>
					{{ element.name }} {{ index }}
				</div>
			</draggable>
		</div>

		<!-- <rawDisplayer class="col-3" :value="list1" title="List 1" />
    rawDisplayer is een component die de json "raw displayed" in een divje
    <rawDisplayer class="col-3" :value="list2" title="List 2" /> -->
	</div>
</template>
<script>
import draggable from "vuedraggable";
// https://github.com/SortableJS/Vue.Draggable
// https://sortablejs.github.io/Vue.Draggable/#/simple

export default {
	components: {
		draggable,
	},
	data() {
		return {
            
			drag: false,
			list1: [
				{ name: "John", id: 1 },
				{ name: "Joao", id: 2 },
				{ name: "Jean", id: 3 },
				{ name: "Gerard", id: 4 },
			],
			list2: [
				{ name: "Juan", id: 5 },
				{ name: "Edgard", id: 6 },
				{ name: "Johnson", id: 7 },
			],
		};
	},
	methods: {
        sort() {
            this.list = this.list.sort((a, b) => a.order - b.order);
        },
		add: function () {
			this.list.push({ name: "Juan" });
		},
		replace: function () {
			this.list = [{ name: "Edgard" }];
		},
		clone: function (el) {
			return {
				name: el.name + " cloned",
			};
		},
		log: function (evt) {
			window.console.log(evt);
		},
	},
    computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  }
};
</script>

<style scoped>
.drag-group1,
.drag-group2 {
	background-color: rgba(255, 180, 180, 0.7);
	padding: 2px 0;
    min-height: 20px;
}

.drag-group1-item,
.drag-group2-item {
	background-color: rgba(170, 170, 255, 1);
	border: 1px solid gray;
	margin: 4px;
    cursor: move;
    cursor: pointer;
}

/* .flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
} */
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

</style>